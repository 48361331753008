<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Service</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <!-- datepicker -->
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <div>
            <label>Range Date</label>
            <div class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="20" />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range' }"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @input="(val) => $emit('update:rangeDateFilter', val)"
              />
              <feather-icon icon="RefreshCwIcon" size="20" @click="reset" />
            </div>
          </div>
        </b-col>
        <!-- datepicker -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    rangeDateFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      rangePicker: [],
      reset: () => {
        this.rangePicker = [];
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
